import React from "react"
import styled, { css } from "styled-components"
import { rem } from "src/utils/styling"
import TabImageOne from "src/images/matilli-tab.png"
import TabImageTwo from "src/images/james-tab.png"
import Button from "src/components/Button"

import ApprenticeBadge from "src/images/apprentice_badge.svg"
import CoderBadge from "src/images/coder_badge.svg"
import ExpertBadge from "src/images/expert_badge.svg"
import GuruBadge from "src/images/guru_badge.svg"
import LeaderBadge from "src/images/leader_badge.svg"
import PractitionerBadge from "src/images/practitioner_badge.svg"
import TabArrowImg from "src/images/tab-arrrow.svg"
import { Link } from "gatsby"
import { DEVICE_TYPE_DESKTOP, useDeviceType } from "src/utils/deviceType"
import { FaArrowDownLong } from "react-icons/fa6"

const Container = styled.div`
  margin: ${rem(80)}rem ${rem(120)}rem;
  @media screen and (max-width: 768px) {
    margin: ${rem(80)}rem 0;
    padding: 0 ${rem(20)}rem;
  }
  @media screen and (min-width: 769px) and (max-width: 1100px) {
    margin: ${rem(80)}rem ${rem(20)}rem;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const TextContainer = styled.div`
  flex: 0.8;
  margin: 0 ${rem(100)}rem 0 0;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
  h1 {
    font-weight: 900;
    font-size: ${rem(42)}rem;
    line-height: ${rem(49)}rem;
    color: #000;
    margin: 0 0 ${rem(10)}rem;
    em {
      color: #00b8c5;
    }
    @media screen and (max-width: 768px) {
      font-size: ${rem(24)}rem;
      line-height: ${rem(28)}rem;
      text-align: center;
    }
    @media screen and (min-width: 769px) and (max-width: 1100px) {
      font-size: ${rem(24)}rem;
      line-height: ${rem(28)}rem;
    }
  }
  p {
    font-size: ${rem(18)}rem;
    line-height: ${rem(34)}rem;
    color: #000;
    @media screen and (max-width: 768px) {
      font-size: ${rem(16)}rem;
      line-height: ${rem(24)}rem;
      text-align: center;
    }
    @media screen and (min-width: 769px) and (max-width: 1100px) {
      font-size: ${rem(16)}rem;
      line-height: ${rem(24)}rem;
    }
  }
`

const ButtonContainer = styled.div`
  flex: 1;
  position: relative;
  @media screen and (max-width: 768px) {
    margin: ${rem(60)}rem 0 0;
  }
`
const OutlineButton = styled.button`
  background: #ffffff;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: ${rem(90)}rem;
  color: #000;
  width: ${rem(200)}rem;
  padding: ${rem(10)}rem 0;
  margin: 0 0 0 ${rem(20)}rem;
  cursor: pointer;
  &.active {
    background-color: #00b8c5;
    color: #fff;
    border: none;
  }
  @media screen and (max-width: 768px) {
    width: ${rem(145)}rem;
    font-size: ${rem(14)}rem;
  }
`

const ApprenticeshipContent = styled.div`
  display: flex;
  background: #102a49;
  border-radius: 20px;
  margin: ${rem(60)}rem 0 0;
  min-height: ${rem(440)}rem;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    min-height: ${rem(500)}rem;
  }
`

const PageButton = styled(Button)`
  transition: 0.2s;
  font-size: ${rem(16)}rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border: 1px solid #eee;
  color: white;
  background-color: transparent;
  padding: ${rem(10)}rem ${rem(16)}rem;
  @media screen and (max-width: 550px) {
    font-size: ${rem(15)}rem;
    padding: 0.4rem 1rem;
  }
  @media screen and (min-width: 768px) {
    margin-right: 10px;
  }
  @media screen and (max-width: 1100px) {
    margin: 0 5px;
  }
  &:hover {
    background-color: white;
    color: black;
  }
`

const GuildContent = styled.div`
  display: flex;
  flex-direction: column;
  background: #00b8c5;
  border-radius: 20px;
  margin: ${rem(60)}rem 0 0;
  min-height: ${rem(440)}rem;
  padding: ${rem(40)}rem;
  @media screen and (max-width: 768px) {
    padding: ${rem(20)}rem;
    flex-direction: column;
    min-height: ${rem(1000)}rem;
  }
`

const BlockTextContainer = styled.div`
  flex: 0.6;
  ${props =>
    props.apprentice &&
    css`
      flex: 1;
    `};
  margin: ${props => (props.apprentice ? `${rem(40)}rem ${rem(40)}rem` : "0")};
  h1 {
    font-weight: 900;
    font-size: ${rem(34)}rem;
    line-height: ${rem(49)}rem;
    color: #fff;
    margin: 0 0 ${rem(10)}rem;
  }
  p {
    font-size: ${rem(16)}rem;
    line-height: ${rem(32)}rem;
    color: #fff;
    margin-bottom: ${rem(30)}rem;
  }
  @media screen and (max-width: 1100px) {
    h1 {
      text-align: center;
    }
  }
  @media screen and (max-width: 768px) {
    padding: $0;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: ${rem(22)}rem;
      line-height: ${rem(28)}rem;
      text-align: center;
    }
    p {
      font-size: ${rem(13)}rem;
      line-height: ${rem(20)}rem;
      margin-bottom: ${rem(18)}rem;
      text-align: center;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1100px) {
    flex: 1;
    h1 {
      font-size: ${rem(22)}rem;
      line-height: ${rem(28)}rem;
    }
    p {
      font-size: ${rem(16)}rem;
      line-height: ${rem(24)}rem;
    }
  }
`

const BlockImageContainer = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  @media screen and (max-width: 768px) {
    justify-content: center;
    padding: ${rem(20)}rem;
  }
`

const ImageOne = styled.img`
  width: ${rem(450)}rem;
  height: auto;
  position: absolute;
  bottom: 0;
  right: ${rem(240)}rem;
  @media screen and (max-width: 768px) {
    width: ${rem(220)}rem;
    right: ${rem(100)}rem;
  }
  @media screen and (min-width: 769px) and (max-width: 1100px) {
    width: ${rem(300)}rem;
    right: ${rem(150)}rem;
  }
`
const Imagetwo = styled.img`
  width: ${rem(280)}rem;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  @media screen and (max-width: 768px) {
    width: ${rem(150)}rem;
  }
  @media screen and (min-width: 769px) and (max-width: 1100px) {
    width: ${rem(200)}rem;
  }
`

const BlockCardContainer = styled.div`
  flex: 1;
  position: relative;
`

const GuildCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: -${rem(100)}rem 0 0;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`

const LeaderCardContainer = styled.div``

const ApprenticeCard = styled.div`
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: ${rem(220)}rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${rem(10)}rem ${rem(20)}rem;
  position: relative;
  margin: 0 ${rem(20)}rem 0;
  height: fit-content;
  min-height: ${rem(250)}rem;
  h4 {
    font-size: ${rem(16)}rem;
    line-height: ${rem(19)}rem;
    padding: ${rem(10)}rem 0;
    text-align: center;
    font-weight: 800;
  }
  p {
    font-size: ${rem(12)}rem;
    line-height: ${rem(18)}rem;
    text-align: center;
    padding: ${rem(5)}rem 0 0;
    color: #010001;
  }
  @media screen and (max-width: 1100px) {
    width: ${rem(180)}rem;
    min-height: ${rem(250)}rem;
    margin: ${rem(10)}rem ${rem(5)}rem;
    padding: ${rem(10)}rem ${rem(10)}rem;
    p {
      padding: 0;
    }
  }
  @media screen and (max-width: 768px) {
    position: relative;
    width: ${rem(140)}rem;
    margin: ${rem(10)}rem ${rem(5)}rem;
    min-height: ${rem(230)}rem;
    padding: 0 ${rem(10)}rem;

    h4 {
      font-size: ${rem(12)}rem;
      line-height: ${rem(18)}rem;
    }
    p {
      font-size: ${rem(10)}rem;
    }
  }
  @media screen and (max-width: 320px) {
    position: relative;
    width: ${rem(120)}rem;
    min-height: ${rem(250)}rem;
  }
`

const CoderCard = styled(ApprenticeCard)``

const LeaderCard = styled(ApprenticeCard)`
  margin: 0 ${rem(20)}rem ${rem(40)}rem;
  @media screen and (max-width: 1100px) {
    margin: ${rem(10)}rem ${rem(5)}rem;
  }
  @media screen and (max-width: 768px) {
    margin: ${rem(10)}rem ${rem(5)}rem;
  }
`

const PractitionerCard = styled(ApprenticeCard)``
const GuruCard = styled(ApprenticeCard)``
const ExpertCard = styled(ApprenticeCard)``

const CardImage = styled.img`
  width: ${rem(100)}rem;
  height: ${rem(100)}rem;
  padding: ${rem(15)}rem 0 0;
  @media screen and (max-width: 768px) {
    width: ${rem(80)}rem;
    height: ${rem(80)}rem;
  }
`
const TabArrow = styled.img`
  height: 100%;
  width: ${rem(140)}rem;
  position: absolute;
  left: ${rem(150)}rem;
  top: -${rem(50)}rem;
  @media screen and (max-width: 768px) {
    left: ${rem(100)}rem;
  }
`

const DoubleCTA = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  color: white;
  margin-top: ${rem(25)}rem;
  ${props =>
    props.apprentice &&
    css`
      flex-direction: column;
      justify-content: center;
      align-items: start;
    `}
  @media screen and (max-width: 1100px) {
    align-items: center;
    flex-direction: column;
  }
`

const DoubleCTAInner = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1100px) {
    display: flex;
    margin-top: ${rem(15)}rem;
  }
  ${props =>
    props.apprentice &&
    css`
      margin-top: ${rem(15)}rem;
    `}
`

const DoubleHeader = styled.h3`
  margin-right: 10px;
  font-weight: 800;
  font-size: 16px;
`

const TabOne = () => (
  <ApprenticeshipContent>
    <BlockTextContainer apprentice>
      <h1>A Global Paid Program For Aspiring Developers</h1>
      <DoubleCTA apprentice>
        <DoubleHeader>I'm interested in</DoubleHeader>
        <DoubleCTAInner apprentice>
          <Link to="/apprenticeship/startups">
            <a>
              <PageButton>Engaging Apprentices</PageButton>
            </a>
          </Link>
          <Link to="/apprenticeship/apprentice">
            <a>
              <PageButton>Being an Apprentice</PageButton>
            </a>
          </Link>
        </DoubleCTAInner>
      </DoubleCTA>
    </BlockTextContainer>
    <BlockImageContainer>
      <ImageOne src={TabImageOne} />
      <Imagetwo src={TabImageTwo} />
    </BlockImageContainer>
  </ApprenticeshipContent>
)

const GuildBandingContainer = styled.div`
  @media screen and (max-width: 1100px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: ${rem(20)}rem 0 0;
  }
`

const GuildBandingContainerLeft = styled.div`
  @media screen and (max-width: 1100px) {
    display: flex;
  }
`

const GuildBandingContainerRight = styled.div`
  @media screen and (max-width: 1100px) {
    display: flex;
  }
`

const IconRowContainer = styled.div`
  display: flex;
  align-items: ceneter
  jusitfy-content: center;
  margin: ${rem(10)}rem 0;
  gap: 50px;
`

const TabTwo = () => {
  const deviceType = useDeviceType()

  return (
    <GuildContent>
      <BlockTextContainer>
        <h1>Engage with world-class Engineering talent</h1>

        <DoubleCTA>
          <DoubleHeader>I am a</DoubleHeader>
          <DoubleCTAInner>
            <Link to="/#offering">
              <a>
                <PageButton>Company</PageButton>
              </a>
            </Link>
            <Link to="/guild">
              <a>
                <PageButton>Developer</PageButton>
              </a>
            </Link>
          </DoubleCTAInner>
        </DoubleCTA>
      </BlockTextContainer>
      <BlockCardContainer>
        {deviceType === DEVICE_TYPE_DESKTOP && (
          <GuildCardContainer>
            <ApprenticeCard>
              <CardImage src={ApprenticeBadge} />
              <h4>Apprentice</h4>
              <p>
                High potential developers, self taught or trained, with little
                to no experience.
              </p>
            </ApprenticeCard>

            <PractitionerCard>
              <CardImage src={PractitionerBadge} />
              <h4>Practitioner</h4>
              <p>
                Developers that have started to realize their potential, but
                need guidance and challenge.
              </p>
            </PractitionerCard>
            <ExpertCard>
              <CardImage src={ExpertBadge} />
              <h4>Expert</h4>
              <p>
                Experienced developers that can serve as team leads and solution
                architects.
              </p>
            </ExpertCard>
            <LeaderCardContainer>
              <LeaderCard>
                <CardImage src={LeaderBadge} />
                <h4>Leader</h4>
                <p>
                  Visionary leaders blending technical expertise and strategy to
                  elevate tech teams.
                </p>
              </LeaderCard>
              <GuruCard>
                <CardImage src={GuruBadge} />
                <h4>Guru</h4>
                <p>
                  Masters of their craft and highly skilled across multiple
                  software development domains.
                </p>
              </GuruCard>
            </LeaderCardContainer>
          </GuildCardContainer>
        )}
        {deviceType !== DEVICE_TYPE_DESKTOP && (
          <GuildBandingContainer>
            <GuildBandingContainerLeft>
              <ApprenticeCard>
                <CardImage src={ApprenticeBadge} />
                <h4>Apprentice</h4>
                <p>
                  High potential developers, self taught or trained, with little
                  to no experience.
                </p>
              </ApprenticeCard>

              <PractitionerCard>
                <CardImage src={PractitionerBadge} />
                <h4>Practitioner</h4>
                <p>
                  Developers that have started to realize their potential, but
                  need guidance and challenge.
                </p>
              </PractitionerCard>
            </GuildBandingContainerLeft>

            <ExpertCard>
              <CardImage src={ExpertBadge} />
              <h4>Expert</h4>
              <p>
                Experienced developers that can serve as team leads and solution
                architects.
              </p>
            </ExpertCard>
            <GuildBandingContainerRight>
              <GuruCard>
                <CardImage src={GuruBadge} />
                <h4>Guru</h4>
                <p>
                  Masters of their craft and highly skilled across multiple
                  software development domains.
                </p>
              </GuruCard>
              <LeaderCard>
                <CardImage src={LeaderBadge} />
                <h4>Leader</h4>
                <p>
                  Visionary leaders blending technical expertise and strategy to
                  elevate tech teams.
                </p>
              </LeaderCard>
            </GuildBandingContainerRight>
          </GuildBandingContainer>
        )}
      </BlockCardContainer>
    </GuildContent>
  )
}
const GuildApprenticeTab = () => {
  const [currentTab, setCurrentTab] = React.useState("guild")

  const renderTab = () => {
    switch (currentTab) {
      case "apprenticeship":
        return <TabOne />
      case "guild":
        return <TabTwo />
      default:
        return <TabOne />
    }
  }

  const handleTabChange = tabValue => setCurrentTab(tabValue)

  return (
    <Container>
      <Header>
        <TextContainer>
          <h1>We are a Global AI & Software Engineering Guild </h1>
          <p>
            Curated by leading engineers trained at ThoughtWorks, Accenture, and
            IBM.
          </p>
        </TextContainer>
        <ButtonContainer>
          <TabArrow src={TabArrowImg} />
          <OutlineButton
            onClick={() => handleTabChange("apprenticeship")}
            className={currentTab === "apprenticeship" ? "active" : ""}
          >
            Apprenticeship
          </OutlineButton>
          <OutlineButton
            onClick={() => handleTabChange("guild")}
            className={currentTab === "guild" ? "active" : ""}
          >
            Guild
          </OutlineButton>
        </ButtonContainer>
      </Header>
      {renderTab()}
      <div id="offering" />
    </Container>
  )
}

export default GuildApprenticeTab
