import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { NewLandingPageHeader } from "src/components/Header"
import { rem } from "src/utils/styling"
import { darkTheme } from "src/utils/themes"
import { PageSection } from "src/components/PageSection"
import Button from "src/components/Button"

import NewSlide from "src/components/apprenticeship/new-slider"
import GuildApprenticeTab from "src/components/apprenticeship/guild-apprentice-tab"
import { LightFooter } from "src/components/Footer"
import ProcessSection from "src/components/apprenticeship/process-section"
import SEO from "src/components/seo"

const Section = styled(PageSection)`
  padding: ${rem(50)}rem 0;
  position: relative;
  background-color: ${props => (props.color ? props.color : "transparent")};
  @media screen and (min-width: 1100px) {
    padding: ${rem(10)}rem 0;
  }
  @media screen and (max-width: 1100px) {
    padding: ${rem(5)}rem 0;
  }
  @media screen and (max-width: 550px) {
    padding: ${rem(20)}rem 0;
  }
`

const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(100)}rem ${rem(140)}rem;
  background: linear-gradient(283.7deg, #03162c 28.07%, #4d5357 185.43%);
  border-radius: 20px;
  width: 90%;
  margin: 0 auto -${rem(180)}rem;
  @media screen and (max-width: 500px) {
    padding: 40px;
  }
  @media screen and (min-width: 1100px) {
    width: 894px;
  }
`

const CTAContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SectionTitle = styled.h2`
  font-weight: 800;
  font-size: ${rem(24)}rem;
  color: ${props => (props.color ? props.color : "#000000")};
  text-align: center;
  align-self: center;

  > em {
    font-style: normal;
    color: #00b8c5;
  }

  @media screen and (min-width: 1100px) {
    font-size: ${rem(34)}rem;
  }
`

export const PageButton = styled(Button)`
  font-size: ${rem(18)}rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media screen and (max-width: 550px) {
    font-size: ${rem(15)}rem;
    padding: 0.4rem 1rem;
  }
  @media screen and (min-width: 1100px) {
    margin: 0;
  }
`

const Index = ({ location }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <SEO
        title="A global remote-native software engineering guild"
        description="Building and scaling the world’s most ambitious startups"
        location={location}
        image="seo_main.png"
      />
      <NewLandingPageHeader />
      <NewSlide />
      <GuildApprenticeTab />
      <ProcessSection />
      <LightFooter />
    </ThemeProvider>
  )
}

export default Index
