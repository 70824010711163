import React, { useRef } from "react"
import styled, { css } from "styled-components"
import { rem } from "src/utils/styling"
import Button from "src/components/Button"
import { DEVICE_TYPE_DESKTOP, useDeviceType } from "src/utils/deviceType"
import { MobileOnlyCarousel } from "src/components/Carousel"

import ReactIcon from "src/images/react-icon.svg"
import ReactIconYellow from "src/images/react-icon-yellow.svg"
import BulletImage from "src/images/bullet-icon.svg"
import TabOneBg from "src/images/tab-one-bg.png"
import TabOneImageTest from "src/images/tab-one-test.png"
import Consult1 from "src/images/consult_1.png"
import TabOneImageStory from "src/images/tab-one-story.svg"
import ReactImage from "src/images/icons/react.svg"
import Java from "src/images/java.svg"
import C from "src/images/c.svg"
import Python from "src/images/python.svg"
import NodeImage from "src/images/icons/node.svg"
import BuildImage from "src/images/icons/build.svg"
import ConsultImage from "src/images/icons/consult.svg"
import HireImage from "src/images/icons/hire.svg"
import BuildYellowImage from "src/images/icons/build-yellow.svg"
import ConsultYellowImage from "src/images/icons/consult-yellow.svg"
import HireYellowImage from "src/images/icons/hire-yellow.svg"
import HireBannerImg from "src/images/hire-banner.svg"
import BuildBoardImage from "src/images/build-board.png"
import BuildBoardMobileImage from "src/images/build-board-mobile.png"
import DesktopHireArrowOneImg from "src/images/desktop-hire-one-arrow.svg"
import DesktopHireArrowTwoImg from "src/images/desktop-hire-two-arrow.svg"
import ProductDevelopmentImage from "src/images/offering/product-development.svg"
import SecurityImageContent from "src/images/offering/security.svg"
import DueDiligenceImage from "src/images/offering/due-diligence.svg"
import FoundationalImage from "src/images/offering/Foundational.svg"
import SwipeableViews from "react-swipeable-views"
import { Link } from "gatsby"
import { HiChevronRight } from "react-icons/hi"
import SudhakarImg from "src/images/sudhakar.svg"
import { ReactTypeformEmbed } from "react-typeform-embed"

const Container = styled.div`
  margin: ${rem(150)}rem ${rem(120)}rem;
  @media screen and (max-width: 1100px) {
    margin: ${rem(50)}rem 0;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const TextContainer = styled.div`
  flex: 0.4;
  margin: 0 ${rem(100)}rem 0 0;
  h1 {
    font-weight: 900;
    font-size: ${rem(42)}rem;
    line-height: ${rem(49)}rem;
    color: #000;
    margin: 0 0 ${rem(10)}rem;
    text-align: center;

    em {
      color: #00b8c5;
    }
  }

  p {
    font-size: ${rem(18)}rem;
    line-height: ${rem(34)}rem;
    color: #000;
    text-align: center;
  }

  @media screen and (max-width: 1100px) {
    padding: 0 ${rem(20)}rem;
    margin: 0;
    flex: 1;
    h1 {
      font-size: ${rem(22)}rem;
      line-height: ${rem(28)}rem;
      text-align: center;
    }
    p {
      font-size: ${rem(16)}rem;
      line-height: ${rem(24)}rem;
      text-align: center;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1100px) {
    flex: 1;
    h1 {
      font-size: ${rem(22)}rem;
      line-height: ${rem(28)}rem;
    }
    p {
      font-size: ${rem(16)}rem;
      line-height: ${rem(24)}rem;
    }
  }
`

const BlockContent = styled.div`
  display: flex;
  margin: ${rem(60)}rem 0 0;
  .mobileNavigator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    padding: ${rem(20)}rem;
    margin: ${rem(20)}rem 0 0;
  }
`

const OfferingContainer = styled.div`
  @media screen and (max-width: 768px) {
    height: ${rem(580)}rem;
  }
`

const OfferingsContent = styled.div`
  padding: ${rem(80)}rem ${rem(40)}rem;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin: 0rem ${rem(120)}rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: ${rem(20)}rem;
    margin: ${rem(50)}rem 0;
  }
`
const Headline = styled.h6`
  font-size: ${rem(22)}rem;
  font-weight: 700;
  margin-bottom: ${rem(15)}rem;
  text-align: left;
  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: ${rem(10)}rem;
    margin-bottom: ${rem(12)}rem;
    margin-top: ${rem(27)}rem;
  }
`

const ShortHeadline = styled.h2`
  color: #000000;
  font-size: ${rem(38)}rem;
  font-weight: 800;
  margin-bottom: ${rem(29)}rem;
  text-align: left;
  @media screen and (max-width: 768px) {
    text-align: center;
    font-size: ${rem(18)}rem;
    margin-bottom: ${rem(12)}rem;
  }
`

const Line = styled.hr`
  width: ${rem(79)}rem;
  marginbottom: ${rem(29)}rem;
  @media screen and (max-width: 768px) {
    margin: ${rem(15)}rem auto;
  }
`

const OfferingsText = styled.div`
  font-size: ${rem(22)}rem;
  font-weight: 700;
  line-height: ${rem(32)}rem;
  color: #072535;
  margin-bottom: ${rem(46)}rem;

  @media screen and (max-width: 768px) {
    width: 100% !important;
    font-weight: 400;
    font-size: ${rem(13)}rem;
    line-height: ${rem(16)}rem;
    text-align: center;
    margin-bottom: ${rem(0)}rem;
  }
`

const OfferingImage = styled.img`
  height: ${rem(480)}rem;
  padding: ${rem(15)}rem 0 0;
  @media screen and (max-width: 768px) {
    width: ${rem(206)}rem;
    height: ${rem(184)}rem;
  }
`

const SecurityImage = styled.div`
  position: relative;
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const SecurityContentDiv = styled.div`
  width: ${rem(467)}rem;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const SecurityImageMobile = styled.div`
  position: relative;
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    margin: 0 auto;
  }
`

const OfferingLearnMore = styled.div`
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const OfferingLearnMoreMobile = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    margin-top: ${rem(24.25)}rem;
    display: block;
  }
`

const Navigator = styled.div`
  margin: ${rem(80)}rem ${rem(150)}rem 0 0;
  @media screen and (max-width: 1100px) {
    margin: 0;
    display: flex;
    justify-content: center;
    gap: ${rem(13)}rem;
  }
`

const ListIcon = styled.img`
  width: ${rem(35)}rem;
  height: ${rem(35)}rem;
  @media screen and (max-width: 1100px) {
    width: ${rem(30)}rem;
    height: ${rem(30)}rem;
  }
`

const ListItem = styled.p`
  font-size: ${rem(21)}rem;
  font-weight: 600;
  color: #5c7491;
  @media screen and (max-width: 1100px) {
    font-size: ${rem(12)}rem;
  }
`

const NavigatorList = styled.div`
  display: flex;
  align-items: center;
  padding: ${rem(10)}rem;
  border-radius: ${rem(24)}rem;
  border: ${rem(0.5)}rem solid #ababab;
  cursor: pointer;
  &.active {
    background-color: #172947;
    color: #f1b456;
    border-radius: ${rem(24)}rem;
    padding: ${rem(10)}rem;
    margin: 0;

    ${ListIcon} {
      fill: #fcb13f;
    }

    ${ListItem} {
      color: #fcb13f;
    }
  }

  @media screen and (max-width: 1100px) {
    &.active {
      padding: ${rem(10)}rem;
    }
  }
`

const PageButton = styled(Button)`
  font-size: ${rem(18)}rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: #fcb13f;
  cursor: pointer;
  @media screen and (max-width: 550px) {
    font-size: ${rem(15)}rem;
    padding: 0.4rem 1rem;
  }
  @media screen and (min-width: 1100px) {
    margin: 0;
  }
`
const TabContent = styled.div`
  flex: 1;
  padding: ${rem(40)}rem ${rem(60)}rem ${rem(80)}rem;
  background-image: url(${TabOneBg});
  border-radius: 20px 0px 0px 20px;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: ${rem(500)}rem;
  h1 {
    font-weight: 900;
    font-size: ${rem(34)}rem;
    line-height: ${rem(49)}rem;
    color: #2f2f2f;
    margin: 0 0 ${rem(10)}rem;
  }
  .subtitle {
    font-size: ${rem(15)}rem;
    line-height: ${rem(24)}rem;
    color: #000;
    width: 50%;
  }
  @media screen and (max-width: 1100px) {
    padding: ${rem(40)}rem 0;
    min-height: ${rem(600)}rem;
    background-image: none;
    h1 {
      font-size: ${rem(22)}rem;
      line-height: ${rem(28)}rem;
      text-align: center;
    }
    .subtitle {
      font-size: ${rem(16)}rem;
      line-height: ${rem(24)}rem;
      text-align: center;
      width: 100%;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 1100px) {
    flex: 1;
    h1 {
      font-size: ${rem(22)}rem;
      line-height: ${rem(28)}rem;
    }
    p {
      font-size: ${rem(16)}rem;
      line-height: ${rem(24)}rem;
    }
  }
`
const BulletContainer = styled.div`
  width: 25%;
  margin: ${rem(0)}rem 0 ${rem(0)}rem;
  @media screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    margin-top: ${rem(10)}rem;
  }
`
const Bullet = styled.div`
  display: flex;
  margin: 0 0 ${rem(10)}rem;
  @media screen and (max-width: 1100px) {
    justify-content: center;
  }
`
const BulletIcon = styled.img`
  width: ${rem(20)}rem;
  height: ${rem(20)}rem;
`
const BulletText = styled.div`
  font-size: ${rem(13)}rem;
  line-height: ${rem(24)}rem;
  color: #2f2f2f;
  margin: 0 0 0 ${rem(10)}rem;
`
const TabOneBannerA = styled.img`
  height: 60%;
  width: auto;
  position: absolute;
  right: 5%;
  bottom: 20%;
  @media screen and (max-width: 1100px) {
    height: 25%;
    width: auto;
    right: 20%;
    bottom: 10%;
  }
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    bottom: -15%;
  }
`
const TabOneBannerB = styled.img`
  height: 100%;
  width: 40%;
  position: absolute;
  right: -5%;
  bottom: -25%;
  @media screen and (max-width: 1100px) {
    height: 100%;
    width: 60%;
    right: 0;
    bottom: -27%;
  }
  @media screen and (min-width: 768px) and (max-width: 1100px) {
    bottom: -30%;
  }
`
const TabTwoBannerA = styled.img`
  height: auto;
  width: 60%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`

const TabThreeBannerA = styled.img`
  height: auto;
  width: 100%;
  @media screen and (max-width: 1100px) {
    margin-bottom: ${rem(40)}rem;
  }
`

const TabFourBannerA = styled.img`
  height: auto;
  width: 100%;
  margin-left: -${rem(20)}rem;
  @media screen and (max-width: 1100px) {
    margin-left: -${rem(20)}rem;
  }
  @media screen and (min-width: 600px) and (max-width: 1100px) {
    width: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`

const StackContainer = styled.div`
  margin: ${rem(30)}rem 0;
  @media screen and (max-width: 1100px) {
    margin: ${rem(13)}rem 0;
  }
`
const StackTitle = styled.p`
  font-size: ${rem(14)}rem;
  margin: 0 0 ${rem(10)}rem;
  font-weight: 600;
  color: #000;
  opacity: 0.5;
  @media screen and (max-width: 1100px) {
    font-size: ${rem(12)}rem;
    text-align: center;
  }
`
const StackImageContainer = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 1100px) {
    justify-content: center;
  }
`
const StackImage = styled.img`
  height: ${rem(40)}rem;
  width: auto;
  margin-right: 2px;
`

const ConsultCard = styled.div`
  border: 1px solid #eeeeee;
  background-color: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${rem(30)}rem ${rem(20)}rem;
  margin: ${rem(30)}rem 0;
  position: absolute;
  left: 50%;
  top: 35%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  width: ${rem(280)}rem;

  @media screen and (max-width: 768px) {
    padding: ${rem(10)}rem ${rem(10)}rem;
    width: ${rem(200)}rem;
    margin: 0 ${rem(50)}rem 0 0;
    left: 40%;
    top: 45%;
  }
`

const ConsultCardImage = styled.img`
  width: 130px;
  height: 130px;
  margin-bottom: ${rem(10)}rem;
  @media screen and (max-width: 1100px) {
    width: 80px;
    height: 80px;
  }
`

const ConsultCardName = styled.p`
  color: #000000;
  font-size: ${rem(16)}rem;
  text-align: center;
  margin-bottom: ${rem(5)}rem;
  @media screen and (max-width: 1100px) {
    font-size: ${rem(14)}rem;
  }
`

const ConsultCardtag = styled.p`
  color: #000000;
  font-weight: 800;
  font-size: ${rem(12)}rem;
  text-align: center;
  margin-bottom: ${rem(5)}rem;
  text-transform: uppercase;
`
const ConsultCardText = styled.p`
  color: #000000;
  font-size: ${rem(14)}rem;
  text-align: center;
  line-height: 22px;
  opacity: 0.5;
  @media screen and (max-width: 1100px) {
    font-size: ${rem(12)}rem;
  }
`

const HWTag = styled.span`
  color: #fff;
  text-transform: uppercase;
  background: linear-gradient(260.25deg, #667eea -10.66%, #764ba2 113%);
  border-radius: 80px;
  padding: ${rem(10)}rem ${rem(40)}rem;
  font-weight: 900;
  font-size: ${rem(12)}rem;
  text-transform: uppercase;
  margin: 0 0 ${rem(10)}rem;

  @media screen and (min-width: 1100px) {
    font-size: ${rem(13)}rem;
  }
`

const ProcessContainer = styled.div`
  margin: 2rem 0;
  display: flex;
  align-item: center;
  position: relative;
`

const ProcessContainerCardTitle = styled.div`
  color: #000;
  text-transform: uppercase;
  font-size: ${rem(15)}rem;
  background: linear-gradient(260.25deg, #e3e3e3 -10.66%, #e7e7e7 113%);
  height: fit-content;
  padding: ${rem(9)}rem ${rem(45)}rem;
  width: fit-content;
  text-align: center;
  border-radius: 80px;
  font-weight: 900;

  @media screen and (min-width: 1100px) {
    font-size: ${rem(15)}rem;
    margin: 0 auto;
  }

  @media screen and (max-width: 1100px) {
    margin: 0 auto;
  }
`

const ProcessContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  height: ${rem(170)}rem;
  max-width: ${rem(273)}rem;
  margin: ${props =>
    props.idx == 2
      ? ` ${rem(50)}rem 0 0`
      : props.idx == 1
      ? `${rem(25)}rem ${rem(20)}rem  0`
      : `0`};
  padding: ${rem(20)}rem ${rem(20)}rem;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.01);
    border: 1px solid #ececec;
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    ${ProcessContainerCardTitle} {
      color: #fff;
      transition: background 0.3s ease;
      background: linear-gradient(260.25deg, #ff6a00 -10.66%, #ee0979 113%);
    }
  }

  @media screen and (max-width: 1100px) {
    margin ${rem(29)}rem;
    width: 100%;
  }
`

const ProcessContainerCardText = styled.div`
  font-size: ${rem(14)}rem;
  color: #000;
  line-height: ${rem(28)}rem;
  font-weight: 300;
  @media screen and (max-width: 550px) {
    line-height: ${rem(28)}rem;
  }
  padding: 0px ${rem(10)}rem;
  text-align: center;
  margin-top: ${rem(15)}rem;
`

const ProcessContainerCardTextContainer = styled.div`
  margin: 0 auto;
  text-align: center;
`

const DesktopHireArrowOne = styled.img`
  height: 100%;
  position: absolute;
  width: 15%;
  top: 50%;
  left: 25%;
`

const DesktopHireArrowTwo = styled.img`
  height: 100%;
  position: absolute;
  width: 15%;
  top: -45%;
  right: 25%;
`

const LearnMoreCodeCTA = styled.div`
  @media screen and (max-width: 1100px) {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    ${props =>
      props.consult &&
      css`
        bottom: -8%;
      `}
  }

  ${props =>
    props.consult &&
    css`
      margin-top: 30px;
      z-index: 9999;
    `}
`

const CodeTab = () => (
  <TabContent>
    <h1>An on-demand software development platform</h1>
    <BulletContainer>
      {[
        "Urgent tasks",
        "Sprint bursts",
        "Spikes",
        "Micro-frontends and microservices/APIs",
      ].map((item, idx) => (
        <Bullet key={idx}>
          <BulletIcon src={BulletImage} />
          <BulletText>{item}</BulletText>
        </Bullet>
      ))}
    </BulletContainer>
    <StackContainer>
      <StackTitle>We Support</StackTitle>
      <StackImageContainer>
        <StackImage src={ReactImage} />
        <StackImage src={Java} />
        <StackImage src={NodeImage} />
        <StackImage src={C} />
        <StackImage src={Python} />
      </StackImageContainer>
    </StackContainer>
    <LearnMoreCodeCTA>
      <Link to="/code">
        <PageButton type="solid">
          Learn More <HiChevronRight size={22} />
        </PageButton>
      </Link>
    </LearnMoreCodeCTA>
    <TabOneBannerA src={TabOneImageTest} />
    <TabOneBannerB src={TabOneImageStory} />
  </TabContent>
)

const BuildTab = () => {
  const deviceType = useDeviceType()
  const typeformBuildRef = useRef()
  const onClickTypeform = () => {
    typeformBuildRef.current && typeformBuildRef.current.typeform.open()
  }

  return (
    <>
      <TabContent>
        <h1>Bring Your Product to Life</h1>
        <p className="subtitle">
          Test your hypothesis with rapid prototyping (2-3 weeks) or MVP
          development (3-6 months), from conceptualization to deployment
        </p>
        {deviceType === DEVICE_TYPE_DESKTOP ? (
          <TabFourBannerA src={BuildBoardImage} />
        ) : (
          <TabFourBannerA src={BuildBoardMobileImage} />
        )}

        <StackContainer>
          <StackTitle>We Support</StackTitle>
          <StackImageContainer>
            <StackImage src={ReactImage} />
            <StackImage src={Java} />
            <StackImage src={NodeImage} />
            <StackImage src={C} />
            <StackImage src={Python} />
          </StackImageContainer>
        </StackContainer>
        <div onClick={onClickTypeform}>
          <PageButton type="solid">
            Learn More <HiChevronRight size={22} />
          </PageButton>
        </div>
      </TabContent>
      <ReactTypeformEmbed
        popup
        autoOpen={false}
        hideHeaders
        hideFooter
        url="https://jfox813621.typeform.com/to/ax1dex2S"
        ref={typeformBuildRef}
      />
    </>
  )
}

const ConsultTab = () => {
  const typeformConsultRef = useRef()
  const onClickTypeform = () => {
    typeformConsultRef.current && typeformConsultRef.current.typeform.open()
  }

  return (
    <>
      <TabContent>
        <h1>Maximise Your Apps' Engineering Potential</h1>
        <p className="subtitle">
          Consult with Experts & Gurus from the Guild with deep domain knowledge
          & expertise
        </p>
        <TabTwoBannerA src={Consult1} />
        <ConsultCard>
          <ConsultCardImage src={SudhakarImg} />
          <ConsultCardName>Sudhakar Rayavaram</ConsultCardName>
          <ConsultCardtag>chennai</ConsultCardtag>
          <HWTag>Guru</HWTag>
          <ConsultCardText>
            Ex ThoughtWorker and an archetype problem solver with 18 years of
            experience.
          </ConsultCardText>
        </ConsultCard>
        <LearnMoreCodeCTA consult>
          <div onClick={onClickTypeform}>
            <PageButton type="solid">
              Learn More <HiChevronRight size={22} />
            </PageButton>
          </div>
        </LearnMoreCodeCTA>
      </TabContent>
      <ReactTypeformEmbed
        popup
        autoOpen={false}
        hideHeaders
        hideFooter
        url="https://jfox813621.typeform.com/to/TWXcnmB8"
        ref={typeformConsultRef}
      />
    </>
  )
}

const hireData = [
  {
    title: "Screening",
    description:
      "Select from pre-screened developers that you would like to audition",
  },
  {
    title: "Audition",
    description:
      "Execute a task-based audition with your candidates guided by our methodology",
  },
  {
    title: "Hire",
    description:
      "Hire your developers full-time once the audition period is complete",
  },
]
const Hiretab = () => {
  const deviceType = useDeviceType()
  const typeformHireRef = useRef()
  const onClickTypeform = () => {
    typeformHireRef.current && typeformHireRef.current.typeform.open()
  }
  return (
    <>
      <TabContent>
        <h1>Engage And Hire Quality Talent</h1>
        <p className="subtitle">
          Hire developers on a interim, fractional or full-time basis to help
          scale your development output
        </p>
        <TabThreeBannerA src={HireBannerImg} />
        {deviceType === DEVICE_TYPE_DESKTOP ? (
          <ProcessContainer>
            <DesktopHireArrowOne src={DesktopHireArrowOneImg} />
            <DesktopHireArrowTwo src={DesktopHireArrowTwoImg} />
            {hireData.map((item, idx) => (
              <ProcessContainerCard idx={idx} key={idx}>
                <ProcessContainerCardTextContainer>
                  <ProcessContainerCardTitle>
                    {item.title}
                  </ProcessContainerCardTitle>
                  <ProcessContainerCardText>
                    {item.description}
                  </ProcessContainerCardText>
                </ProcessContainerCardTextContainer>
              </ProcessContainerCard>
            ))}
          </ProcessContainer>
        ) : (
          <MobileOnlyCarousel>
            {hireData.map((item, idx) => (
              <div style={{ flex: 1 }} key={idx}>
                <ProcessContainerCard idx={idx}>
                  <ProcessContainerCardTextContainer>
                    <ProcessContainerCardTitle>
                      {item.title}
                    </ProcessContainerCardTitle>
                    <ProcessContainerCardText>
                      {item.description}
                    </ProcessContainerCardText>
                  </ProcessContainerCardTextContainer>
                </ProcessContainerCard>
              </div>
            ))}
          </MobileOnlyCarousel>
        )}
        <div style={{ zIndex: 9999 }} onClick={onClickTypeform}>
          <PageButton type="solid">
            Learn More <HiChevronRight size={22} />
          </PageButton>
        </div>
      </TabContent>
      <ReactTypeformEmbed
        popup
        autoOpen={false}
        hideHeaders
        hideFooter
        url="https://jfox813621.typeform.com/to/HWDvADpa"
        ref={typeformHireRef}
      />
    </>
  )
}

const ProductDevelopment = () => {
  return (
    <OfferingContainer style={{ backgroundColor: "#ECE7F5" }}>
      <OfferingsContent>
        <div>
          <Headline style={{ color: "#8A0074" }}>Product Development</Headline>
          <ShortHeadline>A Holistic Approach to Engineering </ShortHeadline>
          <Line style={{ backgroundColor: "#DCA5F6" }} />

          <OfferingsText style={{ width: 501 }}>
            Leverage our three unique product offerings individually or in
            combination to offer seamless flexibility and provide effortless
            scaling at any stage of the product roadmap.
          </OfferingsText>
          <OfferingLearnMore>
            <Link to="/offerings/product-development">
              <PageButton type="solid">
                Learn More <HiChevronRight size={22} />
              </PageButton>
            </Link>
          </OfferingLearnMore>
        </div>
        <div style={{ marginRight: "auto", marginLeft: "auto" }}>
          <OfferingImage style={{ width: 450 }} src={ProductDevelopmentImage} />
        </div>
        <OfferingLearnMoreMobile>
          <Link to="/offerings/product-development">
            <PageButton type="solid">
              Learn More <HiChevronRight size={22} />
            </PageButton>
          </Link>
        </OfferingLearnMoreMobile>
      </OfferingsContent>
    </OfferingContainer>
  )
}

const Security = () => {
  return (
    <OfferingContainer style={{ backgroundColor: "#E5F4FD" }}>
      <OfferingsContent>
        <SecurityImage>
          <OfferingImage style={{ width: 600 }} src={SecurityImageContent} />
        </SecurityImage>
        <SecurityContentDiv>
          <Headline style={{ color: "#39986B" }}>Security</Headline>
          <ShortHeadline>
            Foundation Security and Compliance Realization
          </ShortHeadline>
          <Line style={{ backgroundColor: "#9CCEAF" }} />

          <OfferingsText style={{ width: 467 }}>
            Feel secure in your application with our foundational security
            assessment and restrengthening, or discover how we can assist you in
            achieving your compliance and regulation goals with our experienced
            security team.
          </OfferingsText>
          <OfferingLearnMore>
            <Link to="/offerings/security">
              <PageButton type="solid">
                Learn More <HiChevronRight size={22} />
              </PageButton>
            </Link>
          </OfferingLearnMore>
        </SecurityContentDiv>
        <SecurityImageMobile>
          <OfferingImage style={{ width: 500 }} src={SecurityImageContent} />
        </SecurityImageMobile>
        <OfferingLearnMoreMobile>
          <Link to="/offerings/security">
            <PageButton type="solid">
              Learn More <HiChevronRight size={22} />
            </PageButton>
          </Link>
        </OfferingLearnMoreMobile>
      </OfferingsContent>
    </OfferingContainer>
  )
}

const DueDiligence = () => {
  return (
    <OfferingContainer style={{ backgroundColor: "#ECE7F5" }}>
      <OfferingsContent>
        <div>
          <Headline style={{ color: "#8A0074" }}>Due Diligence</Headline>
          <ShortHeadline> For VCs and Startups</ShortHeadline>
          <Line style={{ backgroundColor: "#DCA5F6" }} />

          <OfferingsText style={{ width: 501 }}>
            We offer tailored Due Diligence based on four key areas to identify
            and benchmark your current ability, including strengths, weaknesses
            and threats across these four areas.
          </OfferingsText>
          <OfferingLearnMore>
            <Link to="/offerings/diligence">
              <PageButton type="solid">
                Learn More <HiChevronRight size={22} />
              </PageButton>
            </Link>
          </OfferingLearnMore>
        </div>
        <div style={{ marginRight: "auto", marginLeft: "auto" }}>
          <OfferingImage style={{ width: 671 }} src={DueDiligenceImage} />
        </div>
        <OfferingLearnMoreMobile>
          <Link to="/offerings/diligence">
            <PageButton type="solid">
              Learn More <HiChevronRight size={22} />
            </PageButton>
          </Link>
        </OfferingLearnMoreMobile>
      </OfferingsContent>
    </OfferingContainer>
  )
}

const ProcessSection = () => {
  const [currentTab, setCurrentTab] = React.useState(0)

  const handleChangeIndex = index => {
    setCurrentTab(index)
  }
  const deviceType = useDeviceType()

  return (
    <>
      <Container>
        <Header>
          <TextContainer>
            <h1>Our Offerings</h1>
            <p>Engage our Guild in multiple ways</p>
          </TextContainer>
        </Header>
      </Container>
      {deviceType === DEVICE_TYPE_DESKTOP ? (
        <>
          <ProductDevelopment />
          <Security />
          <DueDiligence />
        </>
      ) : (
        <div className="mobileNavigator" style={{ position: "relative" }}>
          <Navigator>
            <NavigatorList
              onClick={() => handleChangeIndex(0)}
              className={currentTab === 0 ? "active" : ""}
            >
              <ListItem>Product Development</ListItem>
            </NavigatorList>
            <NavigatorList
              onClick={() => handleChangeIndex(1)}
              className={currentTab === 1 ? "active" : ""}
            >
              <ListItem>Security</ListItem>
            </NavigatorList>
            <NavigatorList
              onClick={() => handleChangeIndex(2)}
              className={currentTab === 2 ? "active" : ""}
            >
              <ListItem>Due Diligence</ListItem>
            </NavigatorList>
          </Navigator>

          <SwipeableViews index={currentTab} onChangeIndex={handleChangeIndex}>
            <ProductDevelopment />
            <Security />
            <DueDiligence />
          </SwipeableViews>
        </div>
      )}
    </>
  )
}

export default ProcessSection
